import React from "react"
import Helmet from 'react-helmet'
import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import '../components/policy.css'


const CPolicy = () => (
    <Layout>
      <SEO title={'Legal'} description={""}/>
      <Container>
        <Helmet>
          <meta name={`robots`} content={`noindex, nofollow`} />
        </Helmet>


        <div id="otnotice-8c479a7e-2f80-4f42-ae5b-d2bd7f0201d8" className="otnotice"></div>

        <Helmet>
          <script src="https://omnicom-privacy-cdn.my.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js" 
          type="text/javascript" 
          charSet="UTF-8" 
          id="otprivacy-notice-script"
          defer
          >
            {`
            settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vb21uaWNvbS1wcml2YWN5Lm15Lm9uZXRydXN0LmNvbS9yZXF1ZXN0L3YxL3ByaXZhY3lOb3RpY2VzL3N0YXRzL3ZpZXdzIn0="
            `}
          </script>

          <script>{`            
            script_ele3 = document.getElementById('otprivacy-notice-script');
            script_ele3.addEventListener("load", () => {

              OneTrust.NoticeApi.Initialized.then(function() {
                OneTrust.NoticeApi.LoadNotices(["https://omnicom-privacy-cdn.my.onetrust.com/c0a325be-6f68-46be-a0de-e4a750890f7d/privacy-notices/8c479a7e-2f80-4f42-ae5b-d2bd7f0201d8.json"]);
              });
              
            });
          `}</script>
        </Helmet>


      </Container>
    </Layout>
  )


export default CPolicy




